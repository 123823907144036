import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ls from 'local-storage'
import GatsbyLink from '../GatsbyLink'
import './Quote.scss'
import { FaShoppingCart } from 'react-icons/fa';

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}
export default class QuoteCartIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: ls.get('quote') ? ls.get('quote') : [],
    }
  }

  componentDidMount() {
    this.interval = setInterval(() =>
      this.setState({ quote: ls.get('quote') ? ls.get('quote') : [] })
    , 1000);
  }

  componentWillUnmount() {
    this.interval = false
    this.setState({})
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { quotePage } = this.props
    return (
      <div className="quote-cart">
        <GatsbyLink onClick={enableBodyScroll} to={quotePage}><FaShoppingCart/>{this.state.quote.length > 0 && <span className="count">{this.state.quote.length}</span>}</GatsbyLink>
      </div>
    )
  }
}
