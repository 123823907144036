import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { isBrowser } from '../../utils/helpers'
import './Image.scss'

export const getImageUrl = (arrayString) => {
  var srcUrlPass = arrayString
  if ( typeof arrayString === 'string' ) return arrayString
  if ( srcUrlPass && srcUrlPass.url && srcUrlPass.url !== "" ) return srcUrlPass.url //Preview format
  if ( srcUrlPass && srcUrlPass.source_url && srcUrlPass.source_url !== "" ) return srcUrlPass.source_url //Passing URL
  if ( srcUrlPass && srcUrlPass.localFile ) return srcUrlPass.localFile.childImageSharp.fluid.src //Child Sharp Option
  return null
}

export const Image = (props) => {

  const { src, position = "absolute", className, alt } = props;

  if (src === undefined) return null

  const srcUrlPass = src && getImageUrl(src)

  if (srcUrlPass === null) return null

  if ( srcUrlPass ) {

    if ( !srcUrlPass.includes('.svg') ) { //Check if svg image before query

      const data = useStaticQuery(graphql`
        query ImageQuery {
          allWordpressWpMedia {
            edges {
              node {
                id
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ... GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `)

      const mediaEdge = data.allWordpressWpMedia.edges
      let image = mediaEdge.filter(m => {
        return m.node.source_url === srcUrlPass;
      })

      if (image && image[0] && image[0].node) {
        image = image[0].node
        if ( image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid) {
            return <Img
            className={`gatsby-image ${className}`}
            fluid={image.localFile.childImageSharp.fluid}
            style={{
               position: position,
               left: 0,
               top: 0,
               width: "100%",
               minHeight:"100%",
             }}
            />;
        }
      }
    }
    if ( srcUrlPass ) {

      return (
        <div
          className="gatsby-image gatsby-image-wrapper"
          style={{
               position: position,
               left: 0,
               top: 0,
               width: "100%",
               minHeight:"100%",
             }}
          >
            <img src={srcUrlPass} className={className} alt={alt} />
        </div>
      )
    }
  }

  return (
    <div
      className={`gatsby-image placeholder ${className}`}
      style={{
         position: position,
         left: 0,
         top: 0,
         width: "100%",
         height: "100%"
       }}
    />
  )
}
